import get from "lodash/get";
import set from "lodash/set";
import deepmerge from "deepmerge";
import { DateTime, DurationUnit } from "luxon";
import { PageSearchParams } from "../types/AppRouter";
import { ZodSchema, ZodObject } from "zod";
import Strings from "./Strings.constants";
import { PublicConfig } from "../PublicConfig";
import { KeyValuePair } from "../types/Quote.interface";
import { UnderwriterConfigKey } from "@/shared/types/Quote.interface";

type MutableRef<T> = React.MutableRefObject<T | null>;
type CallbackRef<T> = (instance: T | null) => void;
type AnyRef<T> = MutableRef<T> | CallbackRef<T>;

export class UIUtils {
    static _browserLanguage: string;

    // Ex mask: (XXX) XXX-XXXX
    static maskPhone = (value: string) => {
        const sanitized = value.replace(/\D/g, "").slice(0, 10);
        const match = sanitized.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            const part1 = match[1] ? `(${match[1]}` : "";
            const part2 = match[2] ? `) ${match[2]}` : "";
            const part3 = match[3] ? `-${match[3]}` : "";
            return `${part1}${part2}${part3}`.trim();
        }
        return value;
    };

    // Ex mask: A1A 1A1
    static maskPostalCodeCA = (value: string) => {
        const sanitized = value
            .replace(/[^A-Za-z0-9]/g, "")
            .toUpperCase()
            .slice(0, 6);

        let formatted = "";

        // Iterate over the sanitized string and apply formatting rules
        for (let i = 0; i < sanitized.length; i++) {
            if (i === 0 || i === 2 || i === 4) {
                // Positions 0, 2, and 4 must be alphabetic (A)
                if (/[A-Za-z]/.test(sanitized[i] || "")) {
                    formatted += sanitized[i];
                } else {
                    break; // Stop if a non-letter is in an alphabetic position
                }
            } else if (i === 1 || i === 3 || i === 5) {
                // Positions 1, 3, and 5 must be numeric
                if (/\d/.test(sanitized[i] || "")) {
                    formatted += sanitized[i];
                } else {
                    break; // Stop if a non-number is in a numeric position
                }
            }

            // Insert space after the first three characters (i.e., between part 1 and part 2)
            if (i === 2 && sanitized.length > 3) {
                formatted += " ";
            }
        }

        return formatted;
    };

    // Ex mask: 02145
    static maskPostalCodeUS = (value: string) => {
        return value.replace(/\D/g, "").slice(0, 5);
    };

    static getBrowserLanguage() {
        if (typeof window !== "undefined" && !!window?.navigator && !UIUtils._browserLanguage) {
            if (navigator.languages != undefined && !!navigator.languages[0]) {
                UIUtils._browserLanguage = navigator.languages[0];
            } else {
                UIUtils._browserLanguage = navigator.language;
            }
        }

        return UIUtils._browserLanguage;
    }

    static formatNumber(number: number, decimalPlaces: number = 0) {
        return number.toLocaleString(UIUtils.getBrowserLanguage(), { maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces });
    }

    static formatCurrency = (amount: number, decimalPlaces: number = 2, locale: string = "en-US", currencyType: string = "USD"): string => {
        // Return N/A if amount is undefined or can't be converted to a number
        // Unary plus operator (+) converts string to number
        if (amount === undefined || isNaN(+amount)) return Strings.NOT_APPLICABLE;
        const amountAsNum = +amount;
        const formatter = new Intl.NumberFormat(locale, {
            style: `currency`,
            currency: currencyType,
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
        });
        return formatter.format(amountAsNum);
    };

    // Takes a date object and returns a formatted string: "November 6, 2023"
    static formatDate(date: Date) {
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
    }

    // Converts 18009051595 to 1-800-905-1595 or 8009051595 to 800-905-1595, using an optional delimiter.
    static formatPhone(phoneNumber: string, delimiter: string = "-") {
        let formattedPhone: string;
        switch (phoneNumber.length) {
            case 11:
                // Handle phone number with country code (11 characters)
                formattedPhone = phoneNumber.slice(0, 1) + delimiter + phoneNumber.slice(1, 4) + delimiter + phoneNumber.slice(4, 7) + delimiter + phoneNumber.slice(7);
                break;
            case 10:
                // Handle phone number without country code (10 characters)
                formattedPhone = phoneNumber.slice(0, 3) + delimiter + phoneNumber.slice(3, 6) + delimiter + phoneNumber.slice(6);
                break;
            default:
                // Handle invalid phone number length
                formattedPhone = "Invalid phone number length";
                break;
        }
        return formattedPhone;
    }

    static getTimezoneAbbreviation = () => {
        const easternTime = DateTime.now().setZone("America/New_York");
        return easternTime.toFormat("ZZZZ");
    };

    static getURLParams(): Record<string, string> {
        const queryParameters = new URLSearchParams(window.location.search);
        return Object.fromEntries(queryParameters.entries());
    }

    static setURLParam(name: string, value: string, replace: boolean = true) {
        const url = new URL(window.location.href);
        url.searchParams.set(name, value);

        if (replace) {
            window.history.replaceState("", "", url.toString());
        } else {
            window.history.pushState("", "", url.toString());
        }
    }

    static toUrlString(pathname: string, urlSearchParams: URLSearchParams): string {
        const BASEPATH = PublicConfig.BASE_PATH;
        let encodedPathname = encodeURIComponent(pathname).replace(/%2F/g, "/"); // Preserve forward slashes
        if (!!BASEPATH && encodedPathname.startsWith(BASEPATH)) {
            encodedPathname = encodedPathname.replace(BASEPATH, "");
        }
        // Build the query parameters string
        const paramsString = urlSearchParams.toString();
        const hasParams = paramsString.length > 0;

        // Build the final URL string
        if (hasParams) {
            return `${encodedPathname}?${paramsString}`;
        } else {
            return `${encodedPathname}`;
        }
    }

    static toURLSearchParams(params: PageSearchParams): URLSearchParams {
        const urlSearchParams = new URLSearchParams();

        for (const [key, value] of Object.entries(params)) {
            if (value === undefined) {
                continue;
            }

            if (Array.isArray(value)) {
                for (const item of value) {
                    urlSearchParams.append(key, item);
                }
            } else {
                urlSearchParams.set(key, value);
            }
        }

        return urlSearchParams;
    }

    static removeURLParam(name: string, replace: boolean = true) {
        const url = new URL(window.location.href);
        url.searchParams.delete(name);

        if (replace) {
            window.history.replaceState("", "", url.toString());
        } else {
            window.history.pushState("", "", url.toString());
        }
    }

    static convertToURLSearchParams = (searchParams: PageSearchParams): URLSearchParams => {
        const urlSearchParams = new URLSearchParams();

        for (const key in searchParams) {
            const value = searchParams[key];
            if (Array.isArray(value)) {
                value.forEach(val => urlSearchParams.append(key, val));
            } else if (value !== undefined) {
                urlSearchParams.append(key, value);
            }
        }

        return urlSearchParams;
    };

    // Returns the value of a key in an object, case-insensitive
    static getCaseInsensitiveValue = (searchParams: URLSearchParams | PageSearchParams, key: string): string | undefined => {
        let urlSearchParams: URLSearchParams;

        if (searchParams instanceof URLSearchParams) {
            urlSearchParams = searchParams;
        } else {
            urlSearchParams = UIUtils.convertToURLSearchParams(searchParams);
        }

        const keyLower = key.toLowerCase();
        let lastValue: string | undefined = undefined;
        for (const [k, v] of urlSearchParams.entries()) {
            if (k.toLowerCase() === keyLower) {
                lastValue = v;
            }
        }
        return lastValue;
    };

    static toggleArray<T = any>(array: T[], ...values: T[]): T[] {
        const set = new Set(array);
        for (const value of values) {
            set.has(value) ? set.delete(value) : set.add(value);
        }
        return Array.from(set);
    }

    static bind(value: any, valuePath: string | string[], callback: ((newValue: any) => void) | undefined, defaultValue?: any) {
        return {
            value: (get(value, valuePath, defaultValue) as string) ?? "",
            onChange: (event: any) => {
                if (callback) {
                    const newValuePart = event.target ? event.target.value : event;
                    const newValue = set({}, valuePath, newValuePart);
                    callback(newValue);
                }
            }
        };
    }

    static deepMerge<T extends Record<string, any>>(
        baseObj: T,
        overrideObj: Record<string, any>,
        baseSchema: ZodSchema<T>
    ): { mergedBase: T; otherProperties: Record<string, any> } {
        let mergedBase: T = { ...baseObj };
        const otherProperties: Record<string, any> = {};

        const mergeArrayById = (target: any[], source: any[]) => {
            if (target[0] && target[0].hasOwnProperty("id")) {
                const merged = [...target];
                source.forEach(srcItem => {
                    const targetItem = target.find(tgtItem => tgtItem.id === srcItem.id);
                    if (targetItem) {
                        const index = merged.indexOf(targetItem);
                        merged[index] = deepmerge(targetItem, srcItem, { arrayMerge: mergeArrayById });
                    } else {
                        merged.push(srcItem);
                    }
                });
                return merged;
            } else {
                return source;
            }
        };

        Object.keys(overrideObj).forEach(key => {
            if (baseSchema instanceof ZodObject && baseSchema.shape && baseSchema.shape[key]) {
                mergedBase = deepmerge(mergedBase, { [key]: overrideObj[key] }, { arrayMerge: mergeArrayById }) as T;
            } else {
                otherProperties[key] = overrideObj[key];
            }
        });

        return { mergedBase, otherProperties };
    }

    static chainRefs<T>(el: T, ...refs: AnyRef<T>[]): void {
        refs.forEach(ref => {
            if (typeof ref === "function") {
                ref(el);
            } else if (ref && typeof ref === "object") {
                ref.current = el;
            }
        });
    }

    /**
     * Smoothly scrolls the window to the top and resolves a promise when the scrolling is complete
     * or the specified timeout has been reached.
     *
     * @param timeout - The maximum time in milliseconds to wait for the scroll to complete (default: 3000)
     * @param element - The element to scroll (default: window)
     * @returns A Promise that resolves when the window has scrolled to the top or the timeout has been reached
     */

    static smoothScrollToTop(timeout = 3000, element?: HTMLElement | null): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                const maxTime = Date.now() + timeout;
                const scrollToOptions: ScrollToOptions = { top: 0, behavior: "smooth" };

                if (element) {
                    element.scrollTo(scrollToOptions);
                } else {
                    window.scrollTo(scrollToOptions);
                }

                function checkIfDone() {
                    const currentScrollPosition = element ? element.scrollTop : window.scrollY;

                    if (currentScrollPosition < 10 || Date.now() > maxTime) {
                        resolve();
                        return;
                    }
                    requestAnimationFrame(checkIfDone);
                }

                checkIfDone();
            } catch (error) {
                reject(error);
            }
        });
    }

    static scrollToTop = (timeout = 500, delay = 100, element?: HTMLElement) => {
        return new Promise<void>((resolve, reject) => {
            try {
                setTimeout(async () => {
                    await UIUtils.smoothScrollToTop(timeout, element);
                    resolve();
                }, delay);
            } catch (error) {
                reject(error);
            }
        });
    };

    static truncateString(str: string, maxLength: number): string {
        return str?.length > maxLength ? str.slice(0, maxLength) + "..." : str;
    }

    static buildUrlWithParams(url: string, params: KeyValuePair[]) {
        const urlWithParams = new URL(url);
        params.forEach(param => {
            urlWithParams.searchParams.append(param.key, param?.value ?? "");
        });
        return urlWithParams.toString();
    }

    // Returns time difference between a given dateTime (ISO) and the current dateTime, in the specified unit
    static getTimeDiffNow = (dateTimeIso: string | undefined, unit: DurationUnit): number => {
        let diff = 0;

        if (dateTimeIso) {
            const currentDateTime = DateTime.utc();
            const updatedDateTime = DateTime.fromISO(dateTimeIso);

            if (updatedDateTime.isValid) {
                diff = currentDateTime.diff(updatedDateTime, unit).as(unit);
            }
        }

        return diff;
    };

    static transformPhoneNumber(phone: string): string {
        // Remove all non-digit characters
        const digitsOnly = phone.replace(/\D/g, "");

        // Ensure we have at least 10 digits
        if (digitsOnly.length < 10) {
            return "";
        }

        // Take the last 10 digits
        const lastTenDigits = digitsOnly.slice(-10);

        // Format the number as (XXX) XXX-XXXX
        return `(${lastTenDigits.slice(0, 3)}) ${lastTenDigits.slice(3, 6)}-${lastTenDigits.slice(6)}`;
    }

    static getPhoneHours = (uw: UnderwriterConfigKey) => {
        const underwriter = uw.toUpperCase() as UnderwriterConfigKey;
        return `${Strings[underwriter].HOURS} ${UIUtils.getTimezoneAbbreviation()}`;
    };
}
